import React, { memo } from "react"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
const StartIndicator = () => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        Puoi avviare il test dei pixel morti facendo clic in un punto qualsiasi
        dello schermo o premendo il tasto{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" /> (a
        destra) della tastiera.
      </p>
      <p className="lead">
        È possibile premere{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        per uscire dal test.
      </p>
    </div>
  )
}
export default memo(StartIndicator)
