import { Link } from "gatsby"
import React, { useRef } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"

import PikselFs from "../../../components/fullscreen/PikselFs"
import Seo from "../../../components/Seo"
import OluPikselGaleri from "../../../contents/Gallery/OluPikselGaleri"
import SvgLeftIcon from "../../../contents/Icons/SvgLeftIcon"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
import SvgPixelTest from "../../../contents/SVG/SvgPixelTest"
import EndIndicator from "../components/EndIndicator"
import StartIndicator from "../components/StartIndicator"
const DeadPixelTest = () => {
  const refPikselFs = useRef()

  return (
    <>
      <Seo
        lang="it"
        keywords="Test morto pixel, test di pixel bloccato, riparazione pixel bloccata, riparazione pixel morto, test di pixel incollato, che è il pixel morto, ciò che è il pixel morto, cosa è bloccato pixel, pixel morto, la differenza pixel morta, il pixel morto, il pixel morto, il pixel morto, Pixel morto, test pixel, pixel bloccato, riparazione pixel online"
        title="Test morto e bloccato pixel"
        description="Test pixel morti e pixel bloccati sul tuo Android, telefoni per iPhone, tablet, PC, televisione intelligente, monitor e schermo del computer sul sito gratuito senza installazione."
      />
      <h1 className="text-center md:text-left">
        Pixel morto o test pixel bloccato
      </h1>
      <p className="lead">
        È possibile rilevare pixel morti o pixel bloccati sullo schermo, se
        presenti, facendo clic sul pulsante{" "}
        <code>Test Dead - Stuck Pixels</code> in basso senza alcuna
        configurazione.
      </p>

      <div
        className="px-4 py-3 text-blue-900 bg-blue-100 border-t-4 border-blue-500 rounded-b shadow-md"
        role="alert"
      >
        <p className="font-bold">
          Prima di iniziare il test del pixel morto - bloccato, dovresti
          considerare:
        </p>
        <p className="">
          Prima di fare il test morto pixel, assicurati che il tuo schermo sia
          pulito e il tuo ambiente non è troppo luminoso.Mentre la polvere sullo
          schermo può ingannarti, un'eccessiva luminosità può farti perdere i
          pixel morti.
        </p>
      </div>

      <div className="mt-10 text-center">
        <PurpleSolidButton
          text="Test Dead - Stuck Pixels"
          onClick={() => refPikselFs.current.open()}
        />
      </div>
      <h2>Come posso testare i pixel morti o i pixel bloccati?</h2>
      <p>
        Dopo aver fatto clic sul pulsante <code>Test Dead - Stuck Pixel</code>{" "}
        sopra, il sito andrà a schermo intero. Le informazioni sono disponibili
        nella schermata che si apre.
      </p>
      <p>
        Per rilevare pixel morti o bloccati, è necessario controllare i colori
        rosso, verde, blu, nero e bianco che occupano lo schermo intero uno per
        uno.
      </p>

      <SvgPixelTest className="max-w-full px-3 md:px-10" />
      <p>
        Per passare da un colore all'altro a schermo intero, puoi fare clic in
        un punto qualsiasi dello schermo con il pulsante sinistro del mouse o
        premere i tasti{" "}
        <SvgLeftIcon className="inline text-purple-700 fill-current" /> e{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" />{" "}
        (sinistra e destra) della tastiera.
      </p>
      <blockquote>
        Prima di iniziare il test pixel morti - pixel bloccati, prestare
        attenzione all'avviso sopra la casella blu delle informazioni in alto.
      </blockquote>
      <p>
        Se rilevi uno o più punti con un colore diverso dal colore di sfondo che
        copre lo schermo, significa che hai un pixel morto o bloccato sullo
        schermo.
      </p>

      <blockquote>
        È possibile premere{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        per uscire dal test.
      </blockquote>
      <p>
        È possibile visualizzare immagini di esempio di pixel morti o bloccati
        facendo clic sul pulsante <code>Dead - Stuck Pixel Samples</code> in
        basso.
      </p>
      <OluPikselGaleri text="Dead - Stuck Pixel Samples" />
      <h2>Cos'è il pixel morto?</h2>
      <p>
        <strong>Dead Pixel</strong> è un pixel che non funziona sullo schermo
        perché non riceve energia. Per questo motivo, i pixel morti sono
        generalmente di colore nero.
      </p>
      <p>
        Sebbene i pixel morti siano per lo più neri, possono essere bianchi su
        alcuni display.
      </p>
      <p>
        Per ulteriori informazioni sulla riparazione dei pixel morti e dei pixel
        morti, è possibile andare alla pagina pertinente facendo clic sul
        pulsante <code className="capitalize">Riparazione pixel morti</code> in
        basso.
      </p>

      <div className="my-3 text-center">
        <Link
          to="/riparazione-pixel-morti"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">Riparazione pixel morti</span>
        </Link>
      </div>

      <h2>Cos'è il pixel bloccato?</h2>
      <p className="lead">
        A differenza dei pixel morti, i pixel bloccati ricevono ancora energia,
        per questo motivo riparare la riparazione dei pixel bloccati è molto più
        semplice.
      </p>
      <p>
        I pixel bloccati sono pixel che rimangono per lo più nei colori di base
        come rosso, verde, blu per motivi come rimanere lo stesso colore per
        molto tempo e riflettono lo stesso colore continuamente.
      </p>
      <p>
        Per ulteriori informazioni sui pixel bloccati e sulla riparazione dei
        pixel bloccati, è possibile andare alla pagina corrispondente facendo
        clic sul pulsante{" "}
        <code className="capitalize">Riparazione pixel bloccati</code> in basso.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/riparazione-pixel-bloccati"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">
            Riparazione pixel bloccati
          </span>
        </Link>
      </div>
      <PikselFs
        ref={refPikselFs}
        startIndicator={<StartIndicator />}
        endIndicator={
          <EndIndicator
            closeTest={() => refPikselFs.current.close()}
            resetTest={() => refPikselFs.current.reset()}
          />
        }
      />
    </>
  )
}
export default DeadPixelTest
