import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
import RedSolidButton from "../../../components/Buttons/RedSolidButton"
const EndIndicator = ({ closeTest, resetTest }) => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        <span role="img" aria-label="Congratulazioni">
          🎉
        </span>
        Hai completato con successo il test morto pixel
        <span role="img" aria-label="Congratulazioni">
          🎉
        </span>
      </p>
      <p className="lead">
        Spero che non ci siano pixel morti sul tuo schermo.
      </p>
      <p className="lead">
        È possibile premere{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        per uscire dal test.
      </p>
      <div>
        <RedSolidButton
          text="Uscita"
          onClick={closeTest}
          style={{ marginRight: "8px" }}
        />
        <PurpleSolidButton text="Ripeti il ​​Test" onClick={resetTest} />
      </div>
    </div>
  )
}
export default memo(EndIndicator)
